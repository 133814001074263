const v118 = {
  version: "1.1.8 (24 de Setembro, 2024)",
  changes: [
    {
      title: "Ferramenta Metas",
      list: [
        {
          description:
            "Corrigida a exibição do botão 'salvar' na Administração de Metas",
        },
      ],
    },
  ],
};

const v117 = {
  version: "1.1.7 (24 de Agosto, 2024)",
  changes: [
    {
      title: "Ferramenta Metas",
      list: [
        {
          description: "Corrigida a exportação de Metas, relatório detalhado.",
        },
      ],
    },
  ],
};

const v116 = {
  version: "1.1.6 (22 de Agosto, 2024)",
  changes: [
    {
      title: "Ferramenta Metas",
      list: [
        {
          description:
            "Correções para tratar adequadamente metas vinculadas a indicadores.",
        },
      ],
    },
  ],
};

const v115 = {
  version: "1.1.5 (26 de Julho, 2024)",
  changes: [
    {
      title: "Plataforma",
      list: [
        {
          description:
            "Separadas as ferramentas em dois grupos, no menu principal: 'Rookau Corporate' e 'Rookau People'.",
        },
      ],
    },
  ],
};

const v114 = {
  version: "1.1.4 (16 de Julho, 2024)",
  changes: [
    {
      title: "Ferramenta Indicadores",
      list: [
        {
          description: "Adicionada coluna 'ID' na exportação para Excel.",
        },
      ],
    },
  ],
};

const v113 = {
  version: "1.1.3 (11 de Julho, 2024)",
  changes: [
    {
      title: "Ferramenta Metas",
      list: [
        {
          description:
            "Relatório detalhado: exibindo valores de planejado, realizado e excedido, mesmo quando ainda não houver valor atual lançado.",
        },
      ],
    },
  ],
};

const v112 = {
  version: "1.1.2 (07 de Julho, 2024)",
  changes: [
    {
      title: "Plataforma",
      list: [
        {
          description:
            "Adicionado middleware para interceptar a tratar adequadamento erros HTTP 401.",
        },
      ],
    },
  ],
};

const v111 = {
  version: "1.1.1 (03 de Julho, 2024)",
  changes: [
    {
      title: "Plataforma",
      list: [
        {
          description:
            "Ajustes no carregamento inicial, para previnir erros de leitura de variáveis 'undefined' (tela branca).",
        },
      ],
    },
  ],
};

const v110 = {
  version: "1.1.0 (03 de Julho, 2024)",
  changes: [
    {
      title: "Plataforma",
      list: [
        {
          description:
            "Ajustes no carregamento inicial, para previnir erros de leitura de variáveis 'undefined' (tela branca).",
        },
      ],
    },
  ],
};

const v1028 = {
  version: "1.0.28 (01 de Julho, 2024)",
  changes: [
    {
      title: "Plataforma",
      list: [
        {
          description:
            "Ajustada a leitura de dados do estado global da aplicação, para previnir erros de leitura de variáveis indefinidas.",
        },
      ],
    },
  ],
};

const v1027 = {
  version: "1.0.27 (01 de Julho, 2024)",
  changes: [
    {
      title: "Ferramenta de Processos",
      list: [
        {
          description:
            "Ajustada a exibição dos popovers informativos na tabela de avaliação de maturidade.",
        },
      ],
    },
  ],
};

const v1026 = {
  version: "1.0.26 (28 de Junho, 2024)",
  changes: [
    {
      title: "Ferramenta de Indicadores",
      list: [
        {
          description:
            "Corrigindo problemas de exibição, dos campos gate, appropriated e exceeded, no dashboard e Excel.",
        },
        {
          description:
            "Corrigindo gravação dos valores, quando Data, na edição dos campos gate, apropriated e exceeded.",
        },
      ],
    },
  ],
};

const v1025 = {
  version: "1.0.25 (28 de Junho, 2024)",
  changes: [
    {
      title: "Ferramenta de Processos",
      list: [
        {
          description:
            "Nova tela de configurações, permitindo configurar as descrições para os critérios e níveis de maturidade.",
        },
        {
          description:
            "Exibição de descrições para os critários de níveis de maturidade na tela de visualização/edição.",
        },
        {
          description: "Adicionado campo ordem nas atividades.",
        },
      ],
    },
  ],
};

const v1024 = {
  version: "1.0.24 (10 de Junho, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Melhorada a exibição das notas e gap, no dashboard e insight.",
        },
      ],
    },
  ],
};

const v1023 = {
  version: "1.0.23 (28 de Maio, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Corrigida a exibição/possibilidade de edição, das notas, no Workflow, na etapa de avaliadores, quando há mais de dois avaliadores diferentes.",
        },
      ],
    },
  ],
};

const v1022 = {
  version: "1.0.22 (24 de Maio, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Corrigida a exibição/possibilidade de edição, das notas, no Workflow, na etapa de avaliadores.",
        },
      ],
    },
  ],
};

const v1021 = {
  version: "1.0.21 (20 de Maio, 2024)",
  changes: [
    {
      title: "Empresa/Cargos da Empresa",
      list: [
        {
          description: "Adicionado campo 'descrição' ao detalhamento do cargo.",
        },
      ],
    },
  ],
};

const v1020 = {
  version: "1.0.20 (17 de Maio, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description: "Corrigindo bug ao enviar o avliado para o quadro.",
        },
      ],
    },
  ],
};

const v1019 = {
  version: "1.0.19 (16 de Maio, 2024)",
  changes: [
    {
      title: "Plataforma Geral",
      list: [
        {
          description: "Removendo service worker.",
        },
      ],
    },
  ],
};

const v1018 = {
  version: "1.0.18 (15 de Maio, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Correção na validação de erros no estágio de avaliação pelo gestor imediato. Não estava permitindo salvar.",
        },
      ],
    },
  ],
};

const v1017 = {
  version: "1.0.17 (02 de Maio, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Aumentado o tamanho do campo 'descrição' das competências para permitir até 1500 caracteres. (tela de edição)",
        },
      ],
    },
  ],
};

const v1016 = {
  version: "1.0.16 (02 de Maio, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Aumentado o tamanho do campo 'descrição' das competências para permitir até 1500 caracteres.",
        },
        {
          description:
            "Corrigida a tela de auto avaliação que cortava a tela dependendo do tamanho de tela.",
        },
      ],
    },
    {
      title: "Organograma da Empresa",
      list: [
        {
          description:
            "Ajustada a exibição em telas menores, pois exibia com zoom de 75%. Removido o uso de zoom.",
        },
      ],
    },
  ],
};

const v1015 = {
  version: "1.0.15 (12 de Abril, 2024)",
  changes: [
    {
      title: "Adicionado gráfico à tabela de subtema.",
      list: [
        {
          description: "Ajustes no Service Worker.",
        },
      ],
    },
  ],
};

const v1014 = {
  version: "1.0.14 (11 de Abril, 2024)",
  changes: [
    {
      title: "Plataforma",
      list: [
        {
          description: "Ajustes no Service Worker.",
        },
      ],
    },
  ],
};

const v1013 = {
  version: "1.0.13 (09 de Abril, 2024)",
  changes: [
    {
      title: "Plataforma",
      list: [
        {
          description: "Testando correções no Service Worker.",
        },
      ],
    },
  ],
};

const v1012 = {
  version: "1.0.12 (09 de Abril, 2024)",
  changes: [
    {
      title: "Plataforma",
      list: [
        {
          description: "Corrigindo service worker.",
        },
      ],
    },
    {
      title: "Empresa",
      list: [
        {
          description: "Permitindo exportar o Organograma em formato PDF.",
        },
      ],
    },
  ],
};

const v1011 = {
  version: "1.0.11 (09 de Abril, 2024)",
  changes: [
    {
      title: "Empresa",
      list: [
        {
          description:
            "Corrigida a exibição dos avatares na exportação do organograma em PDF e SVG.",
        },
      ],
    },
  ],
};

const v1010 = {
  version: "1.0.10 (05 de Abril, 2024)",
  changes: [
    {
      title: "Empresa",
      list: [
        {
          description: "Implementada a exportação do organograma em PDF e SVG.",
        },
      ],
    },
  ],
};

const v109 = {
  version: "1.0.9 (04 de Abril, 2024)",
  changes: [
    {
      title: "Ferramenta de Avaliação de Pessoas",
      list: [
        {
          description: "Exibindo o nome do gestor na Auto Avaliação.",
        },
      ],
    },
  ],
};

const v108 = {
  version: "1.0.8 (04 de Abril, 2024)",
  changes: [
    {
      title: "Ferramenta de Processos",
      list: [
        {
          description:
            "Alterado o texto na dica de relevância ao criar uma atividade.",
        },
        {
          description:
            "Ajustada a largura das colunas na tabela de atividades.",
        },
      ],
    },
  ],
};

const v107 = {
  version: "1.0.7 (26 de Março, 2024)",
  changes: [
    {
      title: "Ferramenta de Indicadores",
      list: [
        {
          description: "Corrigida a criação de novos períodos.",
        },
      ],
    },
  ],
};

const v106 = {
  version: "1.0.6 (12 de Março, 2024)",
  changes: [
    {
      title: "Ferramenta de Processos",
      list: [
        {
          description:
            "Adicionado filtro para ordenação alfabética no cadastro/visualização das Atividades do Insight.",
        },
      ],
    },
  ],
};

const v105 = {
  version: "1.0.5 (7 de Março, 2024)",
  changes: [
    {
      title: "Ferramenta de Indicadores",
      list: [
        {
          description:
            "Ajustado o campo 'planejado', no lançamento dos valores dos indicadores, para considerar apenas duas casas decimais.",
        },
      ],
    },
  ],
};

const v104 = {
  version: "1.0.4 (26 de Fevereiro, 2024)",
  changes: [
    {
      title: "Plataforma Geral",
      list: [
        {
          description: "Ajustando funcionamento do Service Worker",
        },
      ],
    },
  ],
};

const v103 = {
  version: "1.0.3 (23 de Fevereiro, 2024)",
  changes: [
    {
      title: "Plataforma Geral",
      list: [
        {
          description: "Corrigindo mal funcionamento do Service Worker",
        },
      ],
    },
  ],
};

const v102 = {
  version: "1.0.2 (20 de Fevereiro, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Exibindo erro no workflow, quando o tempo de avaliação tiver passado, além de bloquear a abertura da avaliação.",
        },
      ],
    },
  ],
};

const v101 = {
  version: "1.0.1 (14 de Fevereiro, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Corrigida a exibição da avaliação complementar no workflow.",
        },
      ],
    },
  ],
};

const v100 = {
  version: "1.0.0 (04 de Fevereiro, 2024)",
  changes: [
    {
      title: "Ferramenta Indicadores",
      list: [
        {
          description: "Correções na criação de novos ciclos.",
        },
      ],
    },
  ],
};

const v04228 = {
  version: "0.42.28 (25 de Janeiro, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description: "Adicionada nota da avaliação no insight.",
        },
      ],
    },
  ],
};

const v04227 = {
  version: "0.42.27 (19 de Janeiro, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Nota da avaliação: adicionada mensagem 'não realizada', no Insight, quando ainda não tiver avaliação para o colaborador.",
        },
        {
          description:
            "Exibindo no insight: AVALIAÇÃO DE DESEMPENHO (nota do avaliador), AVALIAÇÃO DE FUNÇÃO E POSIÇÃO (apenas gestor imediato e adm. do programa), AVALIAÇÃO DE SUCESSÃO (apenas gestor imediato e adm. do programa)",
        },
      ],
    },
  ],
};

const v04226 = {
  version: "0.42.26 (17 de Janeiro, 2024)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Adicionados campos GAP e Nota da Avaliação, no mapa de liderança e insight.",
        },
      ],
    },
  ],
};

const v04225 = {
  version: "0.42.25 (16 de Janeiro, 2024)",
  changes: [
    {
      title: "Ferramenta de Carreira",
      list: [
        {
          description:
            "Corrigindo erro em tempo de execução, ao criar o insight.",
        },
      ],
    },
  ],
};

const v04224 = {
  version: "0.42.24 (11 de Janeiro, 2024)",
  changes: [
    {
      title: "Ferramenta de Av. de Pessoas",
      list: [
        {
          description: "Formatado o mapa de liderança no insight.",
        },
        {
          description:
            "Adicionada permissão de visualização, para o gestor da ferramenta, para que possa visualizar o mapa de liderança.",
        },
        {
          description:
            "Corrigido o arquivamento de participantes, arquivando mesmo se não tiver sido adicionado ao quadro.",
        },
      ],
    },
  ],
};

const v04223 = {
  version: "0.42.23 (15 de Dezembro, 2023)",
  changes: [
    {
      title: "Ferramenta de Av. de Pessoas",
      list: [
        {
          description:
            "Adicionado botão 'Notificar Gestores', no passo 'Seleção de Avaliadores', no Workflow.",
        },
      ],
    },
  ],
};

const v04222 = {
  version: "0.42.22 (12 de Dezembro, 2023)",
  changes: [
    {
      title: "Ferramenta de Av. de Pessoas",
      list: [
        {
          description: "Ajustes gerais pós homologação.",
        },
      ],
    },
  ],
};

const v04221 = {
  version: "0.42.21 (11 de Dezembro, 2023)",
  changes: [
    {
      title: "Ferramenta de Av. de Pessoas",
      list: [
        {
          description: "Adicionada funcionalidade de seleção de avaliadores.",
        },
      ],
    },
  ],
};

const v04220 = {
  version: "0.42.20 (11 de Dezembro, 2023)",
  changes: [
    {
      title: "Ferramenta de Processos",
      list: [
        {
          description:
            "Aba 'Informações das Atividades': adicionadas informações de 'Total de Tempo' e 'Total de Demandas', para cada área.",
        },
      ],
    },
  ],
};

const v04219 = {
  version: "0.42.19 (07 de Dezembro, 2023)",
  changes: [
    {
      title: "Ferramenta de Processos",
      list: [
        {
          description: "Criado botão para duplicar atividades.",
        },
      ],
    },
    {
      title: "Ferramenta de Avaliação de Pessoas",
      list: [
        {
          description:
            "Corrigidos erros nas atualizações, após escolhas feitas, na Administração.",
        },
      ],
    },
  ],
};

const v04218 = {
  version: "0.42.18 (04 de Dezembro, 2023)",
  changes: [
    {
      title: "Ferramenta de Projetos",
      list: [
        {
          description:
            "Criada média de físico e cronograma por responsável no dashboard 'Performance Geral'.",
        },
      ],
    },
  ],
};

const v04217 = {
  version: "0.42.17 (29 de Novembro, 2023)",
  changes: [
    {
      title: "Ferramenta de Projetos",
      list: [
        {
          description:
            "Corrigida a exibição da tabela de Reporte Executivo, onde estava cortando a última coluna. ",
        },
      ],
    },
  ],
};

const v04216 = {
  version: "0.42.16 (27 de Novembro, 2023)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Adicionado tamanho máximo de 1000 caracteres, em adicionar área de competência e adicionar nova habilidade.",
        },
      ],
    },
  ],
};

const v04215 = {
  version: "0.42.15 (23 de Novembro, 2023)",
  changes: [
    {
      title: "Service Worker",
      list: [
        {
          description: "Ajustes para não usar no ambiente de desenvolvimento.",
        },
      ],
    },
  ],
};

const v04214 = {
  version: "0.42.14 (22 de Novembro, 2023)",
  changes: [
    {
      title: "Ferramenta Plano de Ações",
      list: [
        {
          description:
            "Adicionada coluna de limite de tempo na exportação para Excel.",
        },
      ],
    },
  ],
};

const v04213 = {
  version: "0.42.13 (21 de Novembro, 2023)",
  changes: [
    {
      title: "Ferramenta Plano de Ações",
      list: [
        {
          description: "Adicionada exportação para Excel.",
        },
      ],
    },
  ],
};

const v04212 = {
  version: "0.42.12 (13 de Novembro, 2023)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description:
            "Correção no relacionamento de avaliação desejada, de acordo com o cargo x competência.",
        },
      ],
    },
  ],
};

const v04211 = {
  version: "0.42.11 (10 de Novembro, 2023)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description: "Adicionados campos ID e CPF na exportação para Excel.",
        },
      ],
    },
    {
      title: "Ferramenta Indicadores",
      list: [
        {
          description: "Adicionado campo email na exportação para Excel.",
        },
      ],
    },
    {
      title: "Ninebox",
      list: [
        {
          description: "Ajustada a ordem de exibição.",
        },
      ],
    },
  ],
};

const v04210 = {
  version: "0.42.10 (31 de Outubro, 2023)",
  changes: [
    {
      title: "Geral",
      list: [
        {
          description:
            "Inibindo exibição de usuários inativos nas seleções de responsáveis, membros e participantes.",
        },
      ],
    },
  ],
};

const v0429 = {
  version: "0.42.9 (26 de Outubro, 2023)",
  changes: [
    {
      title: "Ferramenta Indicadores",
      list: [
        {
          description:
            "Corrigida a exibição de gráficos de barras, em indicadores do tipo percentual, com frequência anual e que possuiam atingimento abaixo do mínimo.",
        },
      ],
    },
  ],
};

const v0428 = {
  version: "0.42.8 (11 de Outubro, 2023)",
  changes: [
    {
      title: "Ferramenta Avaliação de Pessoas",
      list: [
        {
          description: "Adicionada borda vermelha para erros no público.",
        },
      ],
    },
    {
      title: "Ferramenta de Processos",
      list: [
        {
          description:
            "Alterado cabeçalho da tabela, campo 'Área' mudado para 'Ator'",
        },
      ],
    },
  ],
};

const v0427 = {
  version: "0.42.7 (03 de Outubro, 2023)",
  changes: [
    {
      title: "Autenticação via CPF",
      list: [
        {
          description: "Habilitando apenas para domínios configurados.",
        },
      ],
    },
  ],
};

const v0426 = {
  version: "0.42.6 (03 de Outubro, 2023)",
  changes: [
    {
      title: "Gerenciamento de Usuários",
      list: [
        {
          description: "Exibição e possibilidade de alteração do campo de CPF.",
        },
      ],
    },
    {
      title: "Importação de Usuários (API Java)",
      list: [
        {
          description: "Adicionados os campos Telefone e CPF.",
        },
      ],
    },
  ],
};

const v0425 = {
  version: "0.42.5 (22 de Setembro, 2023)",
  changes: [
    {
      title: "Ferramenta de Processos",
      list: [
        {
          description:
            "Atividades - Ao inserir responsavél pela atividade, inclui automáticamente em colaboradores do Insight.",
        },
      ],
    },
  ],
};

const v0424 = {
  version: "0.42.4 (21 de Setembro, 2023)",
  changes: [
    {
      title: "Ferramenta de Processos",
      list: [
        {
          description:
            "Atividades - Campo descrição: aumentada a quantidade de caracteres de 250 para 1000.",
        },
        {
          description:
            "Atividades - Alterada a ordem de listagem para exibir por ordem de cadastro e não alfabética.",
        },
        {
          description: "Atividades - Incluída coluna 'Área' do lado esquerdo.",
        },
        {
          description:
            "Atividades - Alterada a exibição do botão de editar para esquerda.",
        },
      ],
    },
  ],
};

const v0423 = {
  version: "0.42.3 (13 de Setembro, 2023)",
  changes: [
    {
      title: "Ferramenta de Processos",
      list: [
        {
          description:
            "Adicionado tooltip explicativo para cada campo da edição da atividade.",
        },
        {
          description: "Alterar a ordem dos campos Frequência e Periodicidade.",
        },
        {
          description: "Adicionada a coluna ID na exportação para Excel.",
        },
        {
          description:
            "Adicionada máscara de horas (hh:mm) nos campos Tempo (min, med e máx).",
        },
      ],
    },
  ],
};

const v0422 = {
  version: "0.42.2 (12 de Setembro, 2023)",
  changes: [
    {
      title: "Service Worker",
      list: [
        {
          description:
            "Correção para evitar erro na inicialização após disponibilização de nova versão ('tela branca').",
        },
      ],
    },
  ],
};

const v0421 = {
  version: "0.42.1 (12 de Setembro, 2023)",
  changes: [
    {
      title: "Perfil do usuário",
      list: [
        {
          description: "Permitindo troca de número de celular.",
        },
      ],
    },
    {
      title: "Autenticação com CPF",
      list: [
        {
          description:
            "Adicionado foco automático na primeira caixa de inserção do código recebido via SMS.",
        },
      ],
    },
  ],
};

const v0420 = {
  version: "0.42.0 (06 de Setembro, 2023)",
  changes: [
    {
      title: "Sistema de Autenticação",
      list: [
        {
          description:
            "Configuração dos domínios que terão acesso a autenticação via CPF.",
        },
        {
          description:
            "Domínio: possibilidade de alterar o telefone do usuário.",
        },
      ],
    },
  ],
};

const v0410 = {
  version: "0.41.0 (04 de Setembro, 2023)",
  changes: [
    {
      title: "Sistema de Autenticação",
      list: [
        {
          description:
            "Adicionada possibilidade de autenticar usando CPF + Celular.",
        },
      ],
    },
  ],
};

const v0402 = {
  version: "0.40.2 (25 de Agosto, 2023)",
  changes: [
    {
      title: "Ferramenta Projetos",
      list: [
        {
          description: "Adicionada mesma largura para o card de porcentagem.",
        },
        {
          description: "Alterada a exibição dos cards (melhorias visuais)",
        },
      ],
    },
  ],
};

const v0401 = {
  version: "0.40.1 (25 de Agosto, 2023)",
  changes: [
    {
      title: "Ferramenta Projetos",
      list: [
        {
          description:
            "Alteração do Card 'Geral' para melhor utilização do espaço e visualização. Os dados do números de projetos foi para o canto superior direito do card e quando passado o mouse em cima ele aparece um 'Tooltip' com o significado desses dados. Também alinhado o card de 'Avanço físico x cronograma' para melhor visualização.",
        },
      ],
    },
  ],
};

const v0400 = {
  version: "0.40.0 (25 de Agosto, 2023)",
  changes: [
    {
      title: "Service Worker",
      list: [
        {
          description:
            "Melhorando configurações de cache e carregamento do sistema.",
        },
      ],
    },
  ],
};

const v0393 = {
  version: "0.39.3 (22 de Agosto, 2023)",
  changes: [
    {
      title: "Ferramenta: Processos",
      list: [
        {
          description:
            "Modal de atividades, campo título: aumentado tamanho máximo para 250 caracteres.",
        },
      ],
    },
  ],
};

const v0392 = {
  version: "0.39.2 (18 de Agosto, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Permitir copiar e colar até mil caracteres no modal de edição de texto",
        },
        {
          description: "Adicionado etc (...) para continuar a descrição.",
        },
        {
          description: "Melhorada a edição em multi linhas.",
        },
      ],
    },
  ],
};

const v0391 = {
  version: "0.39.1 (10 de Agosto, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Alterado o nome da ferramenta para Avaliação de Pessoas",
        },
      ],
    },
  ],
};

const v0390 = {
  version: "0.39.0 (12 de Julho, 2023)",
  changes: [
    {
      title: "Ferramenta: Carreira",
      list: [
        {
          description:
            "Mapa de Liderança, nova tela dashboard para visualizar a relação entre membros avaliadores e liderança.",
        },
      ],
    },
  ],
};

const v0383 = {
  version: "0.38.3 (07 de Julho, 2023)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Melhorias ao salvar projetos e ao salvar atividades do projeto.",
        },
      ],
    },
  ],
};

const v0382 = {
  version: "0.38.2 (03 de Julho, 2023)",
  changes: [
    {
      title: "Ferramenta: OKRs",
      list: [
        {
          description:
            "Módulo Administração, ajustes de ordenação para itens com valores em branco.",
        },
      ],
    },
  ],
};

const v0381 = {
  version: "0.38.1 (27 de Junho, 2023)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Ajuste na formatação do avanço físico ao arredondar casas decimais e ponto flutuante.",
        },
      ],
    },
    {
      title: "Ferramenta: Carreira",
      list: [
        {
          description:
            "Correção do problema que não permitia abrir a tela Medição quando alguns valores de avaliação estavam em branco.",
        },
      ],
    },
  ],
};

const v0380 = {
  version: "0.38.0 (21 de Junho, 2023)",
  changes: [
    {
      title: "Ferramenta: Processos",
      list: [
        {
          description:
            "Atualização no insights de processos, agora é possível cadastrar multiplas atividades para cada processo, incluindo campos para estimativa de tempo e quantidade de demanda.",
        },
        {
          description: "Novo relatório excel para as atividades do projeto.",
        },
      ],
    },
  ],
};

const v0372 = {
  version: "0.37.2 (19 de Junho, 2023)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Ajustes na tabela de projetos da tela Dashboard Reporte Executivo.",
        },
      ],
    },
  ],
};

const v0371 = {
  version: "0.37.1 (13 de Junho, 2023)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Ajuste de formatação para os títulos de projetos no Dashboard Reporte Executivo.",
        },
      ],
    },
  ],
};

const v0370 = {
  version: "0.37.0 (05 de Junho, 2023)",
  changes: [
    {
      title: "Domínio: Gestão de Usuários",
      list: [
        {
          description:
            "Atualização no serviço de desativação do usuário: quando o usuário tiver permissões administrativas, for responsável de uma área ou gestor imediato, agora será possível escolher 1 ou mais membros para substitui-lo.",
        },
      ],
    },
  ],
};

const v03604 = {
  version: "0.36.4 (31 de Maio, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Correção, a formatação de algumas metas do tipo data causavam erro ao editar indicadores.",
        },
      ],
    },
  ],
};

const v03603 = {
  version: "0.36.3 (24 de Maio, 2023)",
  changes: [
    {
      title: "Insight: Comentários",
      list: [
        {
          description:
            "Correção no filtro que não mostrava os comentários conectados de outras questões, temas e cenários ao abrir o Insight.",
        },
      ],
    },
  ],
};

const v03602 = {
  version: "0.36.2 (22 de Maio, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Ajuste na comparação de alterações em andamento em indicadores. O botão salvar não vai mais continuar a ser mostrado mesmo depois de salvar as alterações no banco.",
        },
        {
          description:
            "Novo feedback visual de carregamento/loading ao salvar alterações de indicadores.",
        },
      ],
    },
  ],
};

const v03601 = {
  version: "0.36.1 (15 de Maio, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Novo filtro 'Sem avaliador' adicionado na tela de Movimentações.",
        },
      ],
    },
  ],
};

const v0360 = {
  version: "0.36.0 (15 de Maio, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Nova funcionalidade 'Movimentações' adicionada ao módulo Administração, nova tela com filtros adicionada para facilitar a edição em massa de Gestores e Avaliadores.",
        },
      ],
    },
  ],
};

const v03510 = {
  version: "0.35.10 (09 de Maio, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Novo relatório excel adicionado na tela Dashboard da ferramenta.",
        },
      ],
    },
  ],
};

const v03509 = {
  version: "0.35.9 (03 de Maio, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Atualização no relatório excel de indicadores para mostrar os valores alvo/meta total.",
        },
      ],
    },
  ],
};

const v03508 = {
  version: "0.35.8 (02 de Maio, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Ajuste na formatação de indicadores do tipo data para campos vazios.",
        },
      ],
    },
  ],
};

const v03507 = {
  version: "0.35.7 (02 de Maio, 2023)",
  changes: [
    {
      title: "Quadro: Regras de acesso",
      list: [
        {
          description:
            "Atualizações nas regras de distribuição/remoção de acessos ao trocar responsáveis do insight.",
        },
      ],
    },
  ],
};

const v03506 = {
  version: "0.35.6 (28 de Abril, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "A configuração inicial ao criar uma nova ferramenta Indicadores não estava sendo carregada.",
        },
      ],
    },
  ],
};

const v03505 = {
  version: "0.35.5 (27 de Abril, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Atualização para formatação de indicadores do tipo data no relatório excel de indicadores na tela Relatório.",
        },
      ],
    },
  ],
};

const v03504 = {
  version: "0.35.4 (26 de Abril, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Novos campos adicionados ao relatório excel de indicadores na tela Relatório.",
        },
        {
          description:
            "Ajustes na validação de alterações em indicadores ao abrir pelo Insight.",
        },
        {
          description:
            "Ajustes de estilo na tabela Projetos/Opções no Dashboard Performance.",
        },
      ],
    },
  ],
};

const v03503 = {
  version: "0.35.3 (25 de Abril, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Nova funcionalidade, agora é possível criar indicadores com o relacionamento tipo 'Calculado' e utilizar outros indicadores como métricas.",
        },
      ],
    },
  ],
};

const v03502 = {
  version: "0.35.2 (18 de Abril, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Ajuste na funcionalidade de exclusão de participantes no módulo 'Administração', agora é possível remover um participante sem precisar ir até o quadro e arquivar o insight relacionado.",
        },
      ],
    },
  ],
};

const v03501 = {
  version: "0.35.1 (13 de Abril, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Nova funcionalidade adicionada a tela 'Relatório', agora é possível exportar para excel o relatório de indicadores.",
        },
      ],
    },
  ],
};

const v03500 = {
  version: "0.35.0 (03 de Abril, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Nova funcionalidade no módulo administração, agora é possível gerenciar métricas automáticas para indicadores.",
        },
        {
          description:
            "Nova tela 'Métricas' adicionada ao dashboard com detalhes das métricas criadas e dos indicadores relacionados.",
        },
      ],
    },
  ],
};

const v03433 = {
  version: "0.34.33 (09 de Março, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Nova opção no módulo administração, agora é possível desativar a validação de perguntas obrigátorias no workflow. Permitindo o envio para o próximo estágio mesmo que algumas respostas estejam sem preencher.",
        },
      ],
    },
    {
      title: "Quadro: Cenários",
      list: [
        {
          description:
            "Agora os cenários do quadro são ordenados alfabeticamente.",
        },
      ],
    },
  ],
};

const v03432 = {
  version: "0.34.32 (08 de Março, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Ajuste no carregamento de PDIs ao abrir o diretamente pela tela Dashboard Pessoas.",
        },
      ],
    },
  ],
};

const v03431 = {
  version: "0.34.31 (02 de Março, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Dashboard Pessoas: o texto do botão 'Insight' foi atualizado para 'PDI'.",
        },
        {
          description:
            "Ajuste na regra de acesso para insights de participantes.",
        },
      ],
    },
  ],
};

const v03430 = {
  version: "0.34.30 (28 de Fevereiro, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "A tela inicial da ferramenta foi alterada para mostrar o Dashboard Pessoas.",
        },
        {
          description:
            "Correção, não era possível acessar os insights pelo Dashboard se o usuário logado não fosse um administrador.",
        },
      ],
    },
  ],
};

const v03429 = {
  version: "0.34.29 (22 de Fevereiro, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Atualização de interface e melhorias de loading ao salvar alterações importantes na administração e quadro da ferramenta.",
        },
      ],
    },
  ],
};

const v03428 = {
  version: "0.34.28 (10 de Fevereiro, 2023)",
  changes: [
    {
      title: "Quadro: Avaliação de Competências",
      list: [
        {
          description:
            "Nova funcionalidade 'Mover', agora é possível mover o participante para outro cenário pelo Quadro da ferramenta.",
        },
      ],
    },
  ],
};

const v03427 = {
  version: "0.34.27 (07 de Fevereiro, 2023)",
  changes: [
    {
      title: "Ferramenta: OKRs",
      list: [
        {
          description:
            "Ajuste no cálculo de comparação de score/variação atingida para metas com polaridade positiva.",
        },
      ],
    },
  ],
};

const v03426 = {
  version: "0.34.26 (01 de Fevereiro, 2023)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Melhoria no dashboard, agora é possível selecionar e filtrar por responsáveis.",
        },
        {
          description:
            "Ajuste na formatação de valores do tipo data para indicadores.",
        },
      ],
    },
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Melhorias no módulo Administração, novo filtro por participantes adicionado à tela de gerenciamento do quadro.",
        },
      ],
    },
    {
      title: "Ferramenta: Metas",
      list: [
        {
          description:
            "Ajuste na formatação de valores do tipo data para metas.",
        },
      ],
    },
    {
      title: "Ferramenta: OKRs",
      list: [
        {
          description:
            "Ajuste na formatação do atingimento da meta para objetivos com valores menor que 1.",
        },
      ],
    },
  ],
};

const v03425 = {
  version: "0.34.25 (25 de Janeiro, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Melhorias no módulo Administração, para facilitar a gestão de participantes que serão adicionados ao Quadro da ferramenta.",
        },
      ],
    },
  ],
};

const v03424 = {
  version: "0.34.24 (18 de Janeiro, 2023)",
  changes: [
    {
      title: "Plataforma",
      list: [
        {
          description: "Melhorias de performance e UI.",
        },
      ],
    },
  ],
};

const v03423 = {
  version: "0.34.23 (18 de Janeiro, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "No módulo Administração, foram feitos ajustes para o serviço que faz a atualização/exclusão de perguntas selecionadas da Avaliação Complementar.",
        },
      ],
    },
  ],
};

const v03422 = {
  version: "0.34.22 (11 de Janeiro, 2023)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Adicionada nova ação para abrir o insight do participante pela tela Dashboard Pessoas. Facilitando a busca e análise rápida de participantes.",
        },
        {
          description:
            "Novo sistema de paginação para a tela Dashboard Pessoas, incluindo melhorias de performance.",
        },
        {
          description:
            "Ajuste de validação nas opções obrigatórias do workflow de participantes.",
        },
      ],
    },
  ],
};

const v03421 = {
  version: "0.34.21 (02 de Janeiro, 2023)",
  changes: [
    {
      title: "Plataforma: login",
      list: [
        {
          description:
            "Ajuste na validação inicial do token de acesso, o usuário será redirecionado para a tela de login caso o token esteja expirado.",
        },
      ],
    },
  ],
};

const v03420 = {
  version: "0.34.20 (23 de Dezembro, 2022)",
  changes: [
    {
      title: "Plataforma: página inicial",
      list: [
        {
          description:
            "Redirecionamento para a tela de login removido, agora quando o usuário precisar de autenticação o formulário de login será mostrado diretamente.",
        },
      ],
    },
  ],
};

const v03419 = {
  version: "0.34.19 (21 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Agora quando o modo anônimo de avaliação estiver desligado, a nota dos avaliadores será visível também para os participantes.",
        },
        {
          description:
            "Ajuste no filtro que agrupava os comentários do Workflow por competência selecionada.",
        },
      ],
    },
  ],
};

const v03418 = {
  version: "0.34.18 (20 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Mensagem de validação para campos pendentes no workflow dos participantes foi atualizada.",
        },
      ],
    },
  ],
};

const v03417 = {
  version: "0.34.17 (18 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "A contagem de pendentes e concluídos para os estágios de Aprovação e Concluído foram ajustados na tela Dashboard Overview Status.",
        },
        {
          description:
            "Nova paginação nos temas do quadro para carregar mais rapidamente os itens iniciais. Um novo campo de busca para os temas também foi adicionado.",
        },
      ],
    },
  ],
};

const v03416 = {
  version: "0.34.16 (15 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            'Ao atualizar um participante, o módulo administração continuava em estado "carregando" mesmo depois de finalizar a requisição.',
        },
      ],
    },
  ],
};

const v03415 = {
  version: "0.34.15 (15 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Correção, alguns participantes perdiam o vínculo com o insight ao adicionar no quadro.",
        },
      ],
    },
  ],
};

const v03414 = {
  version: "0.34.14 (14 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "A visualização da Avaliação Complementar no Workflow, agora também será visível nos estágios Aprovação e Concluído para Gestores.",
        },
      ],
    },
  ],
};

const v03413 = {
  version: "0.34.13 (13 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Agora gestores da ferramenta podem sincronizar os acessos perdidos de Gestores e Avaliadores. Acesse a aba Permissões e clique em Sincronizar acessos.",
        },
      ],
    },
  ],
};

const v03412 = {
  version: "0.34.12 (13 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Atualizações no sistema de nível de acesso, ao adicionar ou remover Participantes, Gestores e Avaliadores.",
        },
      ],
    },
  ],
};

const v03411 = {
  version: "0.34.11 (12 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Corrigido, algumas perguntas da avaliação estavam repetidas.",
        },
      ],
    },
  ],
};

const v03410 = {
  version: "0.34.10 (8 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "O formulário de autoavaliação não estava carregando todos os dados da avaliação.",
        },
      ],
    },
  ],
};

const v03409 = {
  version: "0.34.9 (8 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Os layouts dos e-mails de Avaliação de Competências foram atualizados.",
        },
        {
          description:
            "Melhorias gerais de performance no carregamento de dados.",
        },
      ],
    },
  ],
};

const v03408 = {
  version: "0.34.8 (5 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Melhoria na interface de paginação dos participantes na tabela do workflow e administração.",
        },
        {
          description:
            "O workflow será a página inicial a ser carregada ao entrar na ferramenta.",
        },
      ],
    },
  ],
};

const v03407 = {
  version: "0.34.7 (4 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Agora os participantes não precisam confirmar a senha ao clicar no link de convite para a avaliação.",
        },
        {
          description:
            "Gestores imediatos poderão realizar a pesquisa dos participantes mesmo durante o estágio de Autoavaliação. A aprovação final é feita pelo gestor ou administrador depois do estágio Gestores.",
        },
      ],
    },
  ],
};

const v03406 = {
  version: "0.34.6 (1 de Dezembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Novo campo de busca e paginação para a tela Administração do Público.",
        },
      ],
    },
  ],
};

const v03405 = {
  version: "0.34.5 (30 de Novembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Corrigido erro que não deixava cadastrar participantes sem gestores imediatos.",
        },
      ],
    },
  ],
};

const v03404 = {
  version: "0.34.4 (29 de Novembro, 2022)",
  changes: [
    {
      title: "Quadro: Insight",
      list: [
        {
          description:
            "Melhorias de visualização no cabeçalho do insight selecionado.",
        },
        {
          description:
            "Componente Acompanhamento do insight não estava sendo carregado.",
        },
      ],
    },
  ],
};

const v03403 = {
  version: "0.34.3 (29 de Novembro, 2022)",
  changes: [
    {
      title: "Plataforma: geral",
      list: [
        {
          description:
            "O campo de seleção para opções únicas não estava aparecendo.",
        },
      ],
    },
  ],
};

const v03402 = {
  version: "0.34.2 (23 de Novembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Melhorias de usabilidade nos diálogos da avaliação selecionada.",
        },
        {
          description:
            "O campo autoavaliação não é mais obrigatório para avançar aos próximos estágios da avaliação.",
        },
      ],
    },
  ],
};

const v03401 = {
  version: "0.34.1 (23 de Novembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Melhorias de performance na edição do Workflow do participante selecionado.",
        },
      ],
    },
  ],
};

const v03400 = {
  version: "0.34.0 (21 de Novembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Melhorias de usabilidade no Workflow de Competências. Agora, ao selecionar um estágio na tela Workflow, os participantes são ordenados alfabéticamente em formato de lista.",
        },
        {
          description:
            "Na tela Workflow, estágio Autoavaliação, um novo botão foi adicionado, para enviar a pesquisa de avaliação aos participantes selecionados pelo e-mail. Somente gestores da ferramenta podem acessar essa funcionalidade.",
        },
        {
          description:
            "Os participantes que receberem o link de convite, realizarão a pesquisa em um ambiente separado com acesso a sua avaliação individual. Ao finalizar a avaliação, o Gestor imediato também receberá um e-mail para seguir o workflow do participante.",
        },
        {
          description:
            "Novo campo de busca para pesquisar participantes pelo nome ou email no Workflow.",
        },
        {
          description:
            "O estágio de avaliação complementar agora é feito em apenas 1 passo junto com a avaliação dos gestores imediatos.",
        },
      ],
    },
    {
      title: "Empresa: Membros",
      list: [
        {
          description:
            "Agora é possível atualizar a área e cargo dos membros da empresa via importação CSV. Utilize os botões exportar/importar no canto superior direito para editar e enviar a tabela CSV. A hierarquia de cargos e áreas será feita com base no nível importado (ex: 1 = CEO; 1.1 = Diretoria A; 1.2 = Diretoria B; 1.1.1 = Gestores A...).",
        },
      ],
    },
    {
      title: "Empresa: Áreas da Empresa",
      list: [
        {
          description:
            "Agora também é possível atualizar o responsável da área via importação CSV. Utilize o email do membro cadastrado na empresa para atualizar os reponsáveis de cada área.",
        },
      ],
    },
  ],
};

const v03327 = {
  version: "0.33.27 (13 de Outubro, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Responsáveis agora são ordenados alfabéticamente na tela Dashboard Performance Geral de projetos.",
        },
      ],
    },
  ],
};

const v03326 = {
  version: "0.33.26 (11 de Outubro, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Melhorias de performance no carregamento de projetos e do quadro.",
        },
      ],
    },
    {
      title: "Insight: Colaboradores",
      list: [
        {
          description: "Correção da contagem de colaboradores nos insights.",
        },
      ],
    },
  ],
};

const v03325 = {
  version: "0.33.25 (10 de Outubro, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Melhoria de visualização para o campo descrição da atividade na tela Dashboard Atividades.",
        },
      ],
    },
  ],
};

const v03324 = {
  version: "0.33.24 (10 de Outubro, 2022)",
  changes: [
    {
      title: "Workspace: ferramentas",
      list: [
        {
          description:
            "A lista de ferramentas do workspace não era atualizada automaticamente ao adicionar uma nova ferramenta.",
        },
      ],
    },
  ],
};

const v03323 = {
  version: "0.33.23 (10 de Outubro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Agora Participantes e Avaliadores poderão acessar o quadro de insights. Possibilitando o cadastro de PDIs, anexos e comentários.",
        },
      ],
    },
  ],
};

const v03322 = {
  version: "0.33.22 (10 de Outubro, 2022)",
  changes: [
    {
      title: "Ferramentas: Quadro e Dashboards",
      list: [
        {
          description:
            "Ajustes na liberação de acesso do cenário para responsáveis e colaboradores de insights.",
        },
      ],
    },
  ],
};

const v03321 = {
  version: "0.33.21 (06 de Outubro, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "A ordenação automática da lista de atividades do projeto foi ajustada.",
        },
      ],
    },
  ],
};

const v03320 = {
  version: "0.33.20 (05 de Outubro, 2022)",
  changes: [
    {
      title: "Plataforma: Quadro",
      list: [
        {
          description:
            "O carregamento de elementos do quadro, na ferramenta OKR e também em outras ferramentas, foi otimizado.",
        },
      ],
    },
  ],
};

const v03319 = {
  version: "0.33.19 (04 de Outubro, 2022)",
  changes: [
    {
      title: "Tela Inicial",
      list: [
        {
          description: "Removida tela que exibia vídeo de introdução.",
        },
        {
          description: "Direciona direto para tela de login.",
        },
        {
          description:
            "Carrega a logomarca do cliente, no canto superior esquerdo, com o arquivo da primeira empresa cadastrada caso seja um domínio personalizado. (Exemplo: https://nomedocliente.rookau.com).",
        },
      ],
    },
    {
      title: "Plataforma Geral",
      list: [
        {
          description:
            "Atualização da logomarca da Rookau para a versão mais nova.",
        },
        {
          description: "Atualização do favicon.",
        },
      ],
    },
  ],
};

const v03318 = {
  version: "0.33.18 (03 de Outubro, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Melhoria, as subatividades do projeto agora são ordenadas de forma ascendente pela data de cadastro.",
        },
      ],
    },
  ],
};

const v03317 = {
  version: "0.33.17 (27 de Setembro, 2022)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Melhoria, na visão detalhada por Participante da tela Dashboard Pessoas, agora as linhas não avaliadas são filtradas para facilitar a visualização.",
        },
        {
          description:
            "Correção, agora é possível preencher com 0 as avaliações do Workflow para competências que não se aplicam ao perfil do participante.",
        },
        {
          description:
            "Ajuste, melhoria de visualização na descrição da Competência avaliada que aparece ao passar o mouse em cima do título.",
        },
      ],
    },
  ],
};

const v03316 = {
  version: "0.33.16 (30 de Agosto, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Correção, ajuste de visualização na média dos projetos quando o resultado era uma dízima períodica ou com várias casas decimais.",
        },
      ],
    },
  ],
};

const v03315 = {
  version: "0.33.15 (29 de Agosto, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Melhoria, na tela Quadro, agora é possível visualizar a média de avanço físico por insights do tema e subtema.",
        },
      ],
    },
  ],
};

const v03314 = {
  version: "0.33.14 (19 de Agosto, 2022)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Correção, ao entrar na tela Subtemas no dashboard de indicadores enquanto os dados estavam sendo carregados causava tela de erro inesperado.",
        },
      ],
    },
  ],
};

const v03313 = {
  version: "0.33.13 (18 de Agosto, 2022)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Melhoria, agora os subtemas na tela Dashboard são ordenados por ordem alfabética.",
        },
        {
          description:
            "Ajuste, no gráfico detalhado do Indicador, o tamanho do texto foi ajustado para melhorar a visualização.",
        },
      ],
    },
    {
      title: "Quadro: Insights",
      list: [
        {
          description:
            "Correção, quando apenas 1 colaborador estivesse selecionado, não era possível remover o colaborador do insight.",
        },
      ],
    },
  ],
};

const v03312 = {
  version: "0.33.12 (16 de Agosto, 2022)",
  changes: [
    {
      title: "Ferramenta: OKRs",
      list: [
        {
          description:
            "Correção, ao tentar editar um OKR uma tela de erro inesperado estava aparecendo.",
        },
      ],
    },
  ],
};

const v03311 = {
  version: "0.33.11 (15 de Agosto, 2022)",
  changes: [
    {
      title: "Ferramenta: Análise Competitiva",
      list: [
        {
          description:
            "Correção, na tela dashboard mesmo depois da arquivar entidades do quadro, a tela continuava exibindo os dados arquivados.",
        },
      ],
    },
    {
      title: "Ferramenta: Carreira",
      list: [
        {
          description:
            "Correção, na tela dashboard Mapa de Carreira e dentro do insight, o nome dos membros selecionados não estava sendo exibido.",
        },
      ],
    },
  ],
};

const v03310 = {
  version: "0.33.10 (12 de Agosto, 2022)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Correção, na tela dashboard ao abrir o gráfico detalhado do indicador, os valores que eram mostrados ao passar o mouse em cima de um período eram arredondados para cima e portanto não refletiam o valores preenchidos na tabela do indicador.",
        },
      ],
    },
  ],
};

const v03309 = {
  version: "0.33.9 (11 de Agosto, 2022)",
  changes: [
    {
      title: "Linha do Tempo: Comentários",
      list: [
        {
          description:
            "Correção, na ferramenta Indicadores, alguns comentários não mostravam a tag correta com relação ao período/mês do indicador.",
        },
      ],
    },
  ],
};

const v03308 = {
  version: "0.33.8 (04 de Agosto, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Correção, o template de atividades foi reativado e agora será possível criar atividades do projeto a partir de um template selecionado.",
        },
      ],
    },
  ],
};

const v03307 = {
  version: "0.33.7 (01 de Agosto, 2022)",
  changes: [
    {
      title: "Quadro: Insights",
      list: [
        {
          description:
            "Melhoria, agora será possível gerenciar responsáveis e colaboradores separadamente para cada insight. Multiplos membros podem ser colaboradores de um mesmo insight, assim melhorando a interatividade e facilitando a manutenção de dados importantes.",
        },
      ],
    },
  ],
};

const v03306 = {
  version: "0.33.6 (22 de Julho, 2022)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Correção, para metas do tipo quantidade quando o número atingido fosse igual a 1 o resultado formatado era 100 ao invés de 1.00.",
        },
      ],
    },
  ],
};

const v03305 = {
  version: "0.33.5 (14 de Julho, 2022)",
  changes: [
    {
      title: "Logs do Domínio",
      list: [
        {
          description:
            "Correção, não era possível acessar a página de logs do domínio quando nenhuma empresa estivesse selecionada.",
        },
      ],
    },
  ],
};

const v03304 = {
  version: "0.33.4 (01 de Julho, 2022)",
  changes: [
    {
      title: "Quadro: Perfil de Acesso",
      list: [
        {
          description:
            "Correção, líderes do cenário não conseguiam visualizar temas e questões quando nenhum insight havia sido criado.",
        },
      ],
    },
  ],
};

const v03303 = {
  version: "0.33.3 (22 de Junho, 2022)",
  changes: [
    {
      title: "Plataforma: Perfil de Acesso",
      list: [
        {
          description:
            "Melhoria, nova funcionalidade para sincronizar os acesso de usuários com insights, cenários, ferramentas, workspaces e empresas. Adicionado à tela Configurações do Domínio.",
        },
      ],
    },
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Melhoria, ajustes de performance ao cadastrar e editar atividades.",
        },
      ],
    },
  ],
};

const v03302 = {
  version: "0.33.2 (13 de Junho, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Correção, na tela Dashboard Reporte Executivo, o cálculo de estágio do projeto foi corrigido.",
        },
        {
          description:
            "Correção, no Overview Inicial de Projetos, o cálculo de performance foi corrigido.",
        },
        {
          description:
            "Correção, ajuste de validação para bloquear que atividades 100% concluídas sejam salvas sem uma data de entrega.",
        },
      ],
    },
  ],
};

const v03301 = {
  version: "0.33.1 (09 de Junho, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Correção, ajustes para prevenir tela de erro ao entrar na ferramenta.",
        },
      ],
    },
  ],
};

const v03300 = {
  version: "0.33.0 (08 de Junho, 2022)",
  changes: [
    {
      title: "Organograma da Empresa",
      list: [
        {
          description:
            "Melhorias, na interface e interatividade do Organograma da Empresa.",
        },
      ],
    },
    {
      title: "Plataforma: Avatares e fotos de perfil",
      list: [
        {
          description:
            "Melhorias, otimização para a requisição das fotos de perfil prevenindo o carregamento de imagens desnecessárias.",
        },
      ],
    },
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Melhoria, agora ao abrir um insight através da tela dashboard, o indicador carregado será relacionado ao usuário conectado.",
        },
      ],
    },
    {
      title: "Ferramenta: One Page",
      list: [
        {
          description:
            "Ajuste de acesso, agora os responsáveis do insight não poderão acessar a tela Quadro e nem e tela interna do insight.",
        },
      ],
    },
    {
      title: "Empresa: Áreas e Cargos",
      list: [
        {
          description:
            "Correção, em alguns casos não era possível importar áreas ou cargos com o template padrão.",
        },
      ],
    },
    {
      title: "Seleção de Domínio",
      list: [
        {
          description:
            "Melhoria, para usuários que têm acesso de multiplos domínios, agora ao selecionar outro domínio o usuário será redirecionado para a tela de seleção da empresa.",
        },
      ],
    },
    {
      title: "Tela de seleção da Empresa",
      list: [
        {
          description:
            "Correção, não era possível arquivar uma empresa quando nenhuma outra empresa estivesse selecionada.",
        },
      ],
    },
    {
      title:
        "Filtro nas telas Dashboard e Relatório das ferramentas selecionadas",
      list: [
        {
          description:
            "Melhoria, agora o filtro de Cenário, Temas e Questões será persistido para facilitar a navegação entre as telas.",
        },
      ],
    },
  ],
};

const v03226 = {
  version: "0.32.26 (23 de Maio, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Melhorias, ajustes de performance na tabela de Atividades do Projeto selecionado.",
        },
      ],
    },
  ],
};

const v03225 = {
  version: "0.32.25 (23 de Maio, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Melhorias, correções de performance ao abrir o Insight e editar informações do Projeto selecionado.",
        },
        {
          description:
            "Melhorias, correções de performance na tela Quadro da ferramenta.",
        },
      ],
    },
  ],
};

const v03224 = {
  version: "0.32.24 (28 de Abril, 2022)",
  changes: [
    {
      title: "Indicadores e Metas",
      list: [
        {
          description:
            "Melhorias, ajustes na formatação dos valores para indicadores medidos com tipo quantidade.",
        },
      ],
    },
  ],
};

const v03223 = {
  version: "0.32.23 (27 de Abril, 2022)",
  changes: [
    {
      title: "Ferramenta: Metas",
      list: [
        {
          description:
            "Correção, as informações na tela do insight ficavam em branco depois de salvar uma meta pelo Quadro.",
        },
      ],
    },
  ],
};

const v03222 = {
  version: "0.32.22 (22 de Abril, 2022)",
  changes: [
    {
      title: "Ferramenta: Metas",
      list: [
        {
          description:
            "Correção, o diálogo de cadastro de novas meta não limpavam os dados do formulário anterior.",
        },
      ],
    },
  ],
};

const v03221 = {
  version: "0.32.21 (20 de Abril, 2022)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Correção, ajuste no filtro da ferramenta selecionada que em alguns casos causava erro ao entrar na tela Dashboards.",
        },
      ],
    },
  ],
};

const v03220 = {
  version: "0.32.20 (18 de Abril, 2022)",
  changes: [
    {
      title: "Workspace selecionado",
      list: [
        {
          description:
            "Ajustes, correção no filtro e listagem de workspaces sem ferramentas cadastradas.",
        },
      ],
    },
  ],
};

const v03219 = {
  version: "0.32.19 (13 de Abril, 2022)",
  changes: [
    {
      title: "Login: Esqueci minha senha",
      list: [
        {
          description:
            "Correção, alguns caracteres especiais não estavam sendo reconhecidos, agora os caracteres especiais suportados são ($*&@#?!^%).",
        },
      ],
    },
  ],
};

const v03218 = {
  version: "0.32.18 (12 de Abril, 2022)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Correção, alguns indicadores não carregavam completamente na tela Dashboards Performance.",
        },
      ],
    },
  ],
};

const v03217 = {
  version: "0.32.17 (11 de Abril, 2022)",
  changes: [
    {
      title: "Overview Inicial",
      list: [
        {
          description:
            "Correção, outros ajustes de responsividade para telas menores.",
        },
      ],
    },
  ],
};

const v03216 = {
  version: "0.32.16 (11 de Abril, 2022)",
  changes: [
    {
      title: "Overview Inicial",
      list: [
        {
          description: "Correção, ajustes de estilo e responsividade.",
        },
      ],
    },
  ],
};

const v03215 = {
  version: "0.32.15 (11 de Abril, 2022)",
  changes: [
    {
      title: "Overview Inicial: Análise Competitiva",
      list: [
        {
          description:
            "Melhoria, o overview da ferramenta foi divido em 4 visões diferentes para cada cenário utilizado (Swot, Pestal, Concorrentes e Priorização).",
        },
      ],
    },
  ],
};

const v03214 = {
  version: "0.32.14 (04 de Abril, 2022)",
  changes: [
    {
      title: "Insight: Comentários",
      list: [
        {
          description: "Agora é possível excluir os comentários do insight.",
        },
      ],
    },
  ],
};

const v03213 = {
  version: "0.32.13 (17 de Março, 2022)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Melhoria, ajustes na formatação de casas para indicadores do tipo Quantidade.",
        },
      ],
    },
    {
      title: "Ferramenta: OKR",
      list: [
        {
          description:
            "Correção, filtro de resultados duplicados na tela Dashboard Kanban.",
        },
      ],
    },
  ],
};

const v03212 = {
  version: "0.32.12 (11 de Março, 2022)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Correção, não era possível criar a Configuração de Períodos para novas ferramentas Indicadores.",
        },
      ],
    },
  ],
};

const v03211 = {
  version: "0.32.11 (04 de Março, 2022)",
  changes: [
    {
      title: "Configurações da ferramenta selecionada",
      list: [
        {
          description:
            "Correção, a tab Configurações das ferramenta que não suportam customização de períodos não estava carregando.",
        },
      ],
    },
  ],
};

const v03210 = {
  version: "0.32.10 (23 de Fevereiro, 2022)",
  changes: [
    {
      title: "WebSockets: Atualização dinâmica de dados",
      list: [
        {
          description:
            "Correção, a atualização dinâmica em tempo real não estava funcionando.",
        },
      ],
    },
  ],
};

const v0327 = {
  version: "0.32.7 (14 de Fevereiro, 2022)",
  changes: [
    {
      title: "Quadro: Modo de edição",
      list: [
        {
          description: "Correção, a ordenação de temas não estava funcionando.",
        },
      ],
    },
  ],
};

const v0326 = {
  version: "0.32.6 (13 de Fevereiro, 2022)",
  changes: [
    {
      title: "Ferramenta Indicadores: Customização de Período / Ciclo",
      list: [
        {
          description:
            "Correção, líderes de cenário e responsáveis do insight não recebiam acesso ao criar um novo período na ferramenta Indicadores.",
        },
      ],
    },
    {
      title: "Quadro: Cenário",
      list: [
        {
          description:
            "Correção, ao arquivar/desarquivar um cenário, o novo status não carregava dinâmicamente.",
        },
      ],
    },
    {
      title: "Plataforma",
      list: [
        {
          description:
            "Ajustes para melhorar a performance da plataforma e estabilidade no lançamento de novas versões.",
        },
        {
          description: "Correção, overview inicial não buscava os dados.",
        },
      ],
    },
  ],
};

const v0320 = {
  version: "0.32.0 (25 de Janeiro, 2022)",
  changes: [
    {
      title: "Plataforma: Novo sistema de autenticação",
      list: [
        {
          description:
            "Novo, agora para validar o acesso da plataforma Rookau, criamos um sistema próprio de autenticação de dois fatores. Ao entrar na plataforma, todos os usuários verão um diálogo avisando sobre esta atualização e mais instruções sobre como definir sua nova senha.",
        },
      ],
    },
    {
      title: "Ferramenta Indicadores: Customização de Período / Ciclo",
      list: [
        {
          description:
            "Novo, agora é possível configurar diferentes períodos para a ferramenta Indicadores no mesmo Workspace. Ex: Atualmente a ferramenta indicadores '2021' já está ativa no Workspace, e agora dentro da ferramenta na aba Configurações é possível criar um Novo Período para o ano de '2022' e utilizar o ano anterior como template e preencher automaticamente alguns campos para a Administração da ferramenta.",
        },
      ],
    },
    {
      title: "Quadro: Novo modo de edição",
      list: [
        {
          description:
            "Melhoria, agora ficou mais fácil para reordenar Temas, Subtemas e Insights de uma só vez. Ao iniciar a ordenação, o Quadro entra em Modo de Edição, e depois ao finalizar a reordeção desejada, clique em salvar no canto superior direito do Quadro.",
        },
      ],
    },
    {
      title: "Melhorias e Correções Gerais",
      list: [
        {
          description:
            "Av. de Competências: ajustes nas regras de acesso restrito para limitar a visualização de campos sensíveis.",
        },
        {
          description:
            "Av. de Competências: correção, usuário não conseguia fazer autoavaliação.",
        },
        {
          description:
            "Indicadores: melhorias de visualização no gráfico score da tela dashboard.",
        },
        {
          description:
            "Indicadores: correção, comentários não estavam aparecendo.",
        },
        {
          description:
            "Plano de ações: correção, reordenar ações fazia com que o insight perdesse o vínculo com o plano de ação.",
        },
        {
          description:
            "Metas: melhorias no relatório detalhado, novos valores adicionados.",
        },
        {
          description:
            "Projetos: correção, avanço físico não estava sendo automaticamente ajustado ao alterar a data.",
        },
        {
          description:
            "Aprenda mais: inclusão de novos links dos guias de utilização da plataforma.",
        },
        {
          description:
            "Outras melhorias de usabilidade e estabilidade na plataforma.",
        },
      ],
    },
  ],
};

const v0311 = {
  version: "0.31.1 (03 de Setembro, 2021)",
  changes: [
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Ajuste, atualizações nas regras de visualização do Workflow.",
        },
      ],
    },
    {
      title: "Ferramenta: Indicadores e Metas",
      list: [
        {
          description:
            "Melhorias, formatação de valores na tabela de avaliação da meta.",
        },
        {
          description:
            "Correção, filtros dos meses avaliados não mostravam o valor correspondente ao período selecionado nas telas Dashboard.",
        },
        {
          description:
            "Correção, desvio/variação de períodos quando os valores preenchidos eram zero estavam sendo ignorados.",
        },
        {
          description:
            "Correção, botão salvar que não aparecia dentro do insight.",
        },
      ],
    },
    {
      title: "Ferramenta: Planos de Ação",
      list: [
        {
          description:
            "Ajuste de acesso: todos usuários do workspace recebem acesso a ferramenta, mas apenas as ações que o usuário tem acesso serão mostradas.",
        },
      ],
    },
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Correção, botão salvar ficava visível mesmo depois de salvar o projeto.",
        },
        {
          description:
            "Correção, link de notificação não carregava os dados do projeto mencionado.",
        },
      ],
    },
    {
      title: "Plataforma: Quadro",
      list: [
        {
          description: "Correção, reordenação de Temas não estava funcionando.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Ajustes visuais e melhorias de estabilidade para a plataforma.",
        },
      ],
    },
  ],
};

const v0310 = {
  version: "0.31.0 (08 de Agosto, 2021)",
  changes: [
    {
      title: "Plataforma: Gestão de Usuários",
      list: [
        {
          description:
            "Novo, agora é possível enviar convite de cadastro para novos usuários (apenas Proprietários do domínio).",
        },
      ],
    },
    {
      title: "Ferramenta: Plano de Ações",
      list: [
        {
          description:
            "Ajuste, agora os Planos de Ação de Indicadores e Metas serão conectados quando utilizados em mais de 1 insight.",
        },
      ],
    },
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Correção, não era possível editar templates de milestones.",
        },
        {
          description:
            "Correção, o investimento realizado não estava aparecendo ao abrir o insight.",
        },
      ],
    },
    {
      title: "Metas e Indicadores",
      list: [
        {
          description:
            "Ajuste, os comentários de metas e indicadores compartilhados agora podem ser visualizados na tabela da Meta e Indicador.",
        },
      ],
    },
  ],
};

const v0301 = {
  version: "0.30.1 (13 de Julho, 2021)",
  changes: [
    {
      title: "Plataforma: Suporte",
      list: [
        {
          description:
            "Novo, Error Boundaries, sistema para capturar automaticamente erros inesperados nas telas da plataforma.",
        },
      ],
    },
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Novo, email com notificação para os envolvidos quando o projeto é concluído.",
        },
        {
          description:
            "Novo, agora é possível cadastrar e reutilizar templates para Milestones e Atividades.",
        },
      ],
    },
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Novo, opção de transparẽncia na Administração para alternar a visualização de comentários em modo aberto ou modo anõnimo.",
        },
        {
          description:
            "Novo, relatório para Avaliação Geral no Workflow (apenas Gestores da ferramenta).",
        },
        {
          description:
            "Novo, os campos de descrição de Área de Competências e Habilidades foram aumentados para 1000 caracteres.",
        },
        {
          description:
            "Novo, na tela Dashboard: Pessoas, agora é possível clicar no nome do participante para abrir um modal com detalhes da avaliação de Área de Competências e Habilidades do particiapante.",
        },
        {
          description:
            "Novo, na tela Dashboard: Pessoas, agora é possível filtrar pelo nome do Participante.",
        },
      ],
    },
    {
      title: "Ferramenta: Metas",
      list: [
        { description: "Novo, filtro para metas no módulo Administração." },
      ],
    },
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description: "Novo, filtro para indicadores no módulo Administração.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Correção, não era possível acessar o link inteligente quando a sessão não estava logada.",
        },
        {
          description: "Correção, líder do cenário não tinha acesso ao quadro.",
        },
      ],
    },
  ],
};

const v0300 = {
  version: "0.30.0 (21 de Junho, 2021)",
  changes: [
    {
      title: "Plataforma: Gerenciamento",
      list: [
        {
          description:
            "Novo, Dashboard para gerenciar o contrato de licenças do domínio (apenas Proprietários do domínio).",
        },
      ],
    },
    {
      title: "Insight: Comentários",
      list: [
        {
          description:
            "Novo, notificação por e-mail agora envia um link para entrar diretamente no Insight ao ser mencionado em comentários.",
        },
      ],
    },
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Novo, agora é possível entrar diretamente no Projeto mencionado ao receber notificações pelo e-mail.",
        },
      ],
    },
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Novo, configuração de Avaliações Complementares para: Desempenho, Função/Posição e Sucessão.",
        },
        {
          description:
            "Novo, configuração de peso para a Autoavaliação do Participante em Avaliações 360°.",
        },
        {
          description:
            "Novo, configuração para liberar ou bloquear que participantes e avaliadores no Workflow possam visualizar o Gestor Imediato, Cliente ou Fornecedor.",
        },
        {
          description:
            "Novo, estágio no Workflow para as Avaliações Complementares.",
        },
        {
          description:
            "Novo, relatórios para as Avaliações Complementares do Workflow (apenas Gestor da ferramenta).",
        },
        {
          description:
            "Novo, para as ferramenta já existentes, é possível habilitar e importar as configurações padrão para Avaliações Complementares.",
        },
        {
          description:
            "Melhoria, campos de descrição para Área de Competências e Habilidades/Competências.",
        },
        {
          description:
            "Melhoria, no Workflow é possível visualizar as descrições da Área de Competência e Habilidades ao passar o mouse em cima dos títulos.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "A antiga ferramenta SWOT foi removida do modal de cadastro de ferramentas. Agora a ferramenta Análise Competitiva será utilizada para cadastrar novas avaliações SWOT.",
        },
        {
          description:
            "Correção para os filtros nas telas de Visão Geral da ferramenta Projetos.",
        },
        {
          description: "Correção para Workspaces que não eram arquivados.",
        },
        {
          description:
            "Correções de funcionalidade em ferramentas e outras telas.",
        },
      ],
    },
  ],
};

const v0291 = {
  version: "0.29.1 (19 de Maio, 2021)",
  changes: [
    {
      title: "Plataforma: Overview Inicial",
      list: [
        {
          description: "Novo, atualização do Overview de Projetos.",
        },
        {
          description:
            "Melhoria, ajustes na performance para o Overview de Análise Competitiva.",
        },
      ],
    },
    {
      title: "Plataforma: Logs",
      list: [
        {
          description:
            "Melhoria, novos filtros para Empresa, Usuários, Workspaces e Datas, nas telas de logs.",
        },
      ],
    },
    {
      title: "Plataforma: Quadro",
      list: [
        {
          description:
            "Melhoria, correções gerais de performance no Quadro de todas as ferramentas.",
        },
      ],
    },
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Novo, Dashboard Overview adicionado a tela Visão Geral da ferramenta.",
        },
        {
          description:
            "Novo, agora é possível deixar em automático os peso dos Milestones nos Projetos.",
        },
        {
          description:
            "Melhoria, ajustes na contagem de projetos na tela Kanban.",
        },
        {
          description: "Melhoria, ajustes de formatação monetária.",
        },
        {
          description:
            "Melhoria, correções de performance na Visão Geral e Quadro.",
        },
      ],
    },
    {
      title: "Ferramenta: Metas",
      list: [
        {
          description:
            "Novo, agora é possível customizar o ciclo de início e fim das metas. (Ex: Início em Julho de 2021 e Finalizando em Junho de 2022).",
        },
        {
          description:
            "Novo, é possível configurar o ciclo das metas no módulo Administração da ferramenta (Apenas Administradores da Ferrameta).",
        },
        {
          description:
            "Melhoria, correções de performance na Visão Geral e Quadro.",
        },
      ],
    },
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Melhoria, correções de performance na Visão Geral e Quadro.",
        },
      ],
    },
    {
      title: "Ferramenta: Análise Competitiva",
      list: [
        {
          description:
            "Melhoria, ajustes no filtro de insights arquivados nas telas da Visão Geral.",
        },
      ],
    },
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Melhoria, ajustes na ordenação das tabelas de Área de Competências e nas Competências/Habilidades, no módulo Administração.",
        },
        {
          description:
            "Melhoria, ajustes na ordenação dos itens na Visão Geral da ferramenta.",
        },
      ],
    },
    {
      title: "Visão Insight: Comentários",
      list: [
        {
          description:
            "Melhoria, ajustes visuais e de interatividade no campo comentários do Insight.",
        },
        {
          description:
            "Melhoria, ordenação de comentários do mais novo para o mais antigo.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Correções de funcionalidade em ferramentas e outras telas.",
        },
      ],
    },
  ],
};

const v0290 = {
  version: "0.29.0 (15 de Abril, 2021)",
  changes: [
    {
      title: "Plataforma: Overview Inicial",
      list: [
        {
          description:
            "Novo, Overview Inicial para as ferramentas Avaliação de Competências.",
        },
      ],
    },
    {
      title: "Plataforma: Logs",
      list: [
        {
          description:
            "Novo, logs para alterações gerais da empresa e acessos.",
        },
        {
          description:
            "Novo, é possível acessar os logs através do menu superior (ícone de engrenagem) e depois clicando em “Logs”.",
        },
      ],
    },
    {
      title: "Empresa: Identidade Visual",
      list: [
        {
          description:
            "Novo, agora é possível cadastrar a logo da empresa para utilizar no cabeçalho da plataforma.",
        },
        {
          description:
            "Novo, é possível trocar a logo ao entrar em 'Ver detalhes' da empresa selecionada (Apenas proprietários do domínio e administrados da empresa podem acessar).",
        },
      ],
    },
    {
      title: "Ferramenta: Metas",
      list: [
        {
          description:
            "Novo, foram adicionadas mais opções para unidades de medida para Metas.",
        },
        {
          description:
            "Novo, agora é possível cadastrar Plano de Ações para as Metas.",
        },
        {
          description:
            "Novo, tela de Visão Geral para Plano de Ações da ferramenta Metas.",
        },
      ],
    },
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Novo, foram adicionadas mais opções para unidades de medida para Indicadores.",
        },
      ],
    },
    {
      title: "Visão Insight: Comentários",
      list: [
        {
          description:
            "Melhoria, novo componente de comentários para o insight.",
        },
        {
          description:
            "Melhoria, agora é possível adicionar anexos aos comentários.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Correções de estabilidade para requisições e salvamento de dados.",
        },
        {
          description:
            "Correções de funcionalidade em ferramentas e outras telas.",
        },
      ],
    },
  ],
};

const v0281 = {
  version: "0.28.1 (23 de Março, 2021)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Novo, agora é possível filtrar os indicadores pelo mês de início e mês final, nas telas de Visão Geral da ferramenta.",
        },
        {
          description:
            "Novo, linhas suplementares foram adicionadas ao Gráfico Score da Visão Geral Performance, para os valores de Mínimo, Apropriado e Superado de cada período do Indicador.",
        },
        {
          description:
            "Ajustes, agora a Variação do Período e do Ano Anterior ficarão em cor verde (signifcando melhoria de performance), para indicadores em que o valor Superado é menor que o Mínimo (Ex: Indicadores com polaridade invertida ou valores negativos).",
        },
      ],
    },
    {
      title: "Ferramenta: Plano de Ações",
      list: [
        {
          description:
            "Novo, agora é possível anexar arquivos ao criar ou editar ações do Plano de Ação de cada ferramenta.",
        },
      ],
    },
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Ajustes, novas validações ao adicionar milestones na ferramenta.",
        },
      ],
    },
    {
      title: "Plataforma: Geral",
      list: [
        {
          description:
            "Novo, na tela inicial da plataforma, foi adicionado o Relatório Overview da ferramenta Análise Competitiva.",
        },
        {
          description: "Ajustes, melhorias no sistema de zoom da plataforma.",
        },
        {
          description: "Correções para estabilidade de acesso à plataforma.",
        },
      ],
    },
  ],
};

const v0280 = {
  version: "0.28.0 (04 de Março, 2021)",
  changes: [
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Novo, na aba configurações da ferramenta, foi adicionada a opção de configurar os substatus dos projetos.",
        },
        {
          description: "Novo, seleção de substatus para os Projetos.",
        },
        {
          description:
            "Novo, campo substatus na Visão Geral Kanban, Reporte Executivo e Atividades.",
        },
        {
          description:
            "No Overview Inicial da ferramenta Projetos, foram adicionados novos valores para contagem de estágios.",
        },
      ],
    },
    {
      title: "Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Melhoria, automatizações ao selecionar Áreas, Perfis e Participantes no Quadro.",
        },
        {
          description:
            "Ajuste, na Visão Geral o Status 'Avaliação Gestor' foi trocada por 'Avaliação Geral'.",
        },
      ],
    },
    {
      title: "Ferramenta: Metas",
      list: [
        {
          description:
            "Melhoria, novos campos no relatório (excel) do Workflow.",
        },
        {
          description:
            "Melhoria, novos campos no relatório (excel) da Visão Geral Metas.",
        },
      ],
    },
    {
      title: "Plataforma",
      list: [
        {
          description:
            "Novo, funcionalidade para aumentar e diminuir o zoom da Plataforma. Agora diferentes telas terão mais espaço para visualização de dados em páginas como a Visão Geral das Ferramentas.",
        },
      ],
    },
  ],
};

const v0271 = {
  version: "0.27.1 (09 de Fevereiro, 2021)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Novo, agora é possível fazer comentários/observações para cada período do indicador.",
        },
        {
          description:
            "Nova coluna de comentários foi adicionada à tabela de Avaliação do Indicador, acessável ao abrir o Insight e Gráfico detalhado na Visão Geral da Ferramenta.",
        },
        {
          description:
            "Novo, agora é possível alterar a distribuição dos alvos do indicador à partir valor Apropriado. Na tabela de Avaliação do Indicador, clique no valor Apropriado do período desejado e altere o valor.",
        },
      ],
    },
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Novas funcionalidades para gerenciar e visualizar Atividades (to-do) para os Projetos.",
        },
      ],
    },
    {
      title: "Melhorias Gerais",
      list: [
        {
          description:
            "Ajuste, agora ao conectar insights, o outro insight também será conectado.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Ajustes ao carregar os dados da Visão Geral na ferramenta Indicadores.",
        },
      ],
    },
  ],
};

const v0270 = {
  version: "0.27.0 (29 de Janeiro, 2021)",
  changes: [
    {
      title: "Ferramenta: Indicadores",
      list: [
        {
          description:
            "Agora é possível adicionar os valores do Período/Ano Anterior de Indicadores.",
        },
        {
          description:
            "Novos campos de Variação do Período Anterior e Período Atual, no Insight View e Visão Geral.",
        },
        {
          description:
            "Agora é possível configurar o Ciclo do Período Anual (mês inicial e final) para Avaliação de Indicadores na tela Configurações do Módulo Administração.",
        },
      ],
    },
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Novo filtro para Responsáveis de Milestones na Visão Geral.",
        },
        {
          description:
            "Nova notificação por e-mail quando um novo Responsável do Milestone for selecionado.",
        },
        {
          description:
            "Novo campo de observações na Visão Geral, ao passar o mouse sobre Atividades/Milestones.",
        },
      ],
    },
    {
      title: "Ferramenta: Metas e Bônus",
      list: [
        {
          description:
            "Novo filtro para alternar entre o atingimento de Metas ou Bônus na tela Visão Geral Metas.",
        },
        {
          description:
            "Novo campo Qtd. Salários foi adicionado ao exportar o Relatório de Bônus da Visão Geral Metas.",
        },
        {
          description:
            "Ajuste para metas com polo invertido e valores de atingimento zero.",
        },
      ],
    },
    {
      title: "Plataforma",
      list: [
        {
          description:
            "Nova botão flutuante 'Suporte' para captar e atender solicitações de clientes.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Ajuste em requisições para carregamento dinâmico de dados.",
        },
        {
          description:
            "Empresas e Workspaces arquivados não serão mostrados na tela de seleção.",
        },
        {
          description:
            "O campo Avanço Físico de Milestones não aceitava valores decimais.",
        },
        {
          description: "Outros ajustes visuais da Plataforma.",
        },
      ],
    },
  ],
};

const v0261 = {
  version: "0.26.1 (8 de Janeiro, 2021)",
  changes: [
    {
      title: "Nova Ferramenta: Avaliação de Competências",
      list: [
        {
          description:
            "Visando facilitar a avaliação e acompanhamento de habilidades e competências dos membros da empresa, a nova ferramenta Avaliação de Competências foi adicionada à Plataforma.",
        },
        {
          description:
            "Novo sistema de Workflow para Avaliação de Competências.",
        },
        {
          description:
            "Novas telas de Visão Geral para análise de dados consolidados.",
        },
      ],
    },
    {
      title: "Nova Ferramenta: Análise Competitiva",
      list: [
        {
          description:
            "A ferramenta foi adicionada à Plataforma para possibilitar análises estratégicas de diversos cenários importantes para sua empresa. SWOT, Pestal, Priorização e Concorrentes em uma só ferramenta.",
        },
        {
          description:
            "Novas telas de Visão Geral para análise de dados consolidados.",
        },
      ],
    },
    {
      title: "Ferramenta: Plano de Ações",
      list: [
        {
          description:
            "Novo, o Overview Inicial da ferramenta Plano de Ações foi adicionada à tela inicial da Plataforma.",
        },
      ],
    },
    {
      title: "Ferramenta: Processos",
      list: [
        {
          description:
            "Melhorias na tela de Visão Geral da ferramenta Processos.",
        },
      ],
    },
    {
      title: "Ferramenta: Carreira",
      list: [
        {
          description:
            "Melhorias na tela de Visão Geral da ferramenta Carreira.",
        },
      ],
    },
    {
      title: "Ferramenta: Projetos",
      list: [
        {
          description:
            "Ajustes de ordenação + novos campos adicionados à tela Visão Geral.",
        },
        {
          description:
            "Melhorias na listagem e edição de atividades/milestones.",
        },
      ],
    },
    {
      title: "Ferramenta: Metas e Bônus",
      list: [
        {
          description: "Ajustes na tabela do Workflow da ferramenta.",
        },
      ],
    },
    {
      title: "Quadro: Geral",
      list: [
        {
          description:
            "Melhorias para edição de Cenários, Temas, Subtemas e Insights do Quadro.",
        },
      ],
    },
    {
      title: "Quadro: Insight",
      list: [
        {
          description:
            "Agora é possível mencionar outros membros da Plataforma no campo de comentários. Digite @ e o nome do usuário para utilizar esta funcionalidade.",
        },
      ],
    },
    {
      title: "Melhorias Gerais",
      list: [
        {
          description:
            "Agora ao passar o mouse sobre a foto de um usuário, será mostrado o seu nome.",
        },
        {
          description:
            "Agora é possível filtrar por Questões/Subtemas nas telas de Visão Geral das ferramentas.",
        },
        {
          description:
            "Agora as ferramentas inativas são filtradas do Overview Inicial da Empresa.",
        },
        {
          description:
            "Nova contagem de caracteres permitidos em campos de texto.",
        },
        {
          description: "Ajustes visuais geral para a Plataforma e Ferramentas.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Ajustes para atualizações dinâmicas na ferramenta Plano de Ações.",
        },
      ],
    },
  ],
};

const v0260 = {
  version: "0.26.0 (16 de Outubro, 2020)",
  changes: [
    {
      title: "Nova Ferramenta: Plano de Ações",
      list: [
        {
          description:
            "A ferramenta foi adicionada à Plataforma para facilitar o acompanhamento e gestão de Plano de Ações. O rastreamento é feito de forma Centralizada por cada Workspace.",
        },
        {
          description:
            "Nova tela de Visão Geral exclusiva, feita para o acompanhamento de Plano de Ações agrupados por Ferramentas do Workspace.",
        },
        {
          description:
            "Nova tela de Visão Geral exclusiva, feita para o acompanhamento de Plano de Ações agrupados por Responsáveis de cada Ação planejada.",
        },
      ],
    },
    {
      title: "Ferramenta Projetos",
      list: [
        {
          description:
            "Agora é possível exportar em PDF a tela Visão Geral de Atividades.",
        },
        {
          description:
            "Ajustes gerais na formatação, visualização de dados e outras melhorias visuais.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Não era possível reiniciar o status de atividades na ferramenta Projetos.",
        },
        {
          description:
            "Ajustes para a requisição de dados gerais de ferramentas.",
        },
        {
          description:
            "Ajustes de formatação de dados e exportação para Excel.",
        },
        {
          description:
            "Correção de fotos de perfil dos responsáveis na ferramenta OKR e Carreira.",
        },
      ],
    },
  ],
};

const v0251 = {
  version: "0.25.1 (16 de Setembro, 2020)",
  changes: [
    {
      title: "Metas e Bônus",
      list: [
        {
          description:
            "Melhoria, agora através do Módulo Administração, ao cadastrar ou editar metas, é possível adicioná-las diretamente à Participantes que estão adicionados ao Quadro.",
        },
        {
          description:
            "Melhoria, agora é possível cadastrar multiplus Cargos de uma só vez no Módulo Administração.",
        },
        {
          description:
            "Melhoria, agora é possível cadastrar multiplus Participantes de uma só vez no Módulo Administração.",
        },
        {
          description:
            "Melhoria, ao cadastrar as Metas de um Participante ao Quadro, o Participante será adicionado como Responsável automaticamente.",
        },
        {
          description:
            "Melhoria, agora é possível baixar um relatório CSV do status de Workflow das Metas dos Participantes.",
        },
        {
          description:
            "Validações removidas para a Quantidade de Salários dos Cargos, visando agilidade no cadastro simplificado.",
        },
        {
          description:
            "Validações removidas para a Base Salarial de Participantes, visando agilidade no cadastro simplificado.",
        },
        {
          description:
            "Atualizações gerais para comparação de entidades e consistência de dados.",
        },
      ],
    },
    {
      title: "Indicadores",
      list: [
        {
          description:
            "Novo, agora o Relacionamento de um Indicador pode ser configurado como “Consolidado” (o Indicador Consolidado não altera o Score de Atingimento agrupado).",
        },
        {
          description:
            "Foram adicionadas customizações visuais para o Indicador Consolidado.",
        },
        {
          description:
            "Melhoria, nas telas de Visão Geral, agora os Indicadores são ordenados à partir da ordem configurada no Quadro da ferramenta.",
        },
      ],
    },
    {
      title: "Modal do Insight",
      list: [
        {
          description:
            "Melhoria, o layout do Modal foi atualizado para ficar fixado na tela, o botão para fechar o Modal agora está sempre visível e também o cabeçalho do Insight.",
        },
      ],
    },
    {
      title: "Melhorias de Performance",
      list: [
        {
          description:
            "O número e peso de requisições diminuíram em várias telas e principalmente no Overview Inicial.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description: "Correções de layout na ferramenta Processos.",
        },
        {
          description:
            "Correções para edição da Base de Ciclos na ferramenta OKR.",
        },
        {
          description:
            "Correção, Status Não Iniciado para as atividades com 0% de progresso na ferramenta Projetos.",
        },
        {
          description:
            "Correção, a validação para importação e exportação de Metas e Indicadores foi ajustada.",
        },
        {
          description:
            "Ajustes de funcionamento para o Organograma da Empresa.",
        },
        {
          description:
            "Ajustes de validação para exclusão de Áreas e Cargos da Empresa.",
        },
      ],
    },
  ],
};

const v0250 = {
  version: "0.25.0 (24 de Agosto, 2020)",
  changes: [
    {
      title: "Metas e Bônus",
      list: [
        {
          description:
            "Nova funcionalidade para distribuição de targets e customizações nas metas.",
        },
      ],
    },
    {
      title: "Indicadores",
      list: [
        {
          description:
            "Nova funcionalidade para distribuição de targets e customizações nas metas.",
        },
      ],
    },
    {
      title: "Projetos",
      list: [
        {
          description:
            "Nova tela de Visão Geral por Atividades foi adicionada à ferramenta.",
        },
        {
          description:
            "Novos dados foram adicionados a tela Visão Geral Mapa Kanban.",
        },
        {
          description: "Melhorias visuais nas telas de Visão Geral",
        },
      ],
    },
    {
      title: "OKR",
      list: [
        {
          description:
            "Novos dados foram adicionados a tela Visão Geral Performance.",
        },
      ],
    },
    {
      title: "Sistema de Notificações",
      list: [
        {
          description:
            "Agora as notificações apresentam o caminho de origem da notificação.",
        },
        {
          description:
            "Nova notificação ao adicionar um Administrador na Empresa.",
        },
        {
          description: "Nova notificação ao adicionar um Workspace na Empresa.",
        },
        {
          description:
            "Nova notificação ao adicionar um Moderador no Workspace.",
        },
        {
          description:
            "Nova notificação ao adicionar uma Ferramenta no Workspace.",
        },
        {
          description: "Nova notificação ao adicionar um Gestor na Ferramenta.",
        },
        {
          description:
            "Nova notificação ao adicionar um Visualizador na Ferramenta.",
        },
        {
          description: "Nova notificação ao adicionar um Líder no Cenário.",
        },
        {
          description:
            "Nova notificação ao adicionar um Visualizador no Cenário.",
        },
        {
          description:
            "Agora é possível excluir notificações que já foram visualizadas.",
        },
      ],
    },
    {
      title: "Organograma da Empresa",
      list: [
        {
          description:
            "Agora é possível visualizar o Organograma da Empresa selecionada na Tela Inicial da Plataforma.",
        },
      ],
    },
    {
      title: "Melhorias da Plataforma",
      list: [
        {
          description: "Melhorias gerais no Kanban das ferramentas.",
        },
        {
          description: "Agora é possível importar a Estrutura de Áreas.",
        },
        {
          description: "Agora é possível importar a Estrutura de Cargos.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description: "Correções de layout na ferramenta Projetos.",
        },
        {
          description: "Correção do upload de fotos no perfil.",
        },
      ],
    },
  ],
};

const v0241 = {
  version: "0.24.1 (12 de Agosto, 2020)",
  changes: [
    {
      title: "Melhorias da Plataforma",
      list: [
        {
          description:
            "Agora é possível reordenar Temas, Questões e Insights no Quadro das ferramentas.",
        },
      ],
    },
    {
      title: "Melhorias de Ferramentas",
      list: [
        {
          description:
            "Novo campo Detalhes da Atividade para a ferramenta Processos.",
        },
      ],
    },
    {
      title: "Melhorias de Interatividade",
      list: [
        {
          description: "Novo campo responsável para a Estrutura de Áreas.",
        },
        {
          description:
            "Agora é possível exportar a Estrutura de Áreas e Cargos da Empresa.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Instabilidade na exclusão de times da ferramenta Metas e Bônus.",
        },
      ],
    },
  ],
};

const v0240 = {
  version: "0.24.0 (24 de Julho, 2020)",
  changes: [
    {
      title: "Melhorias de Ferramentas",
      list: [
        {
          description:
            "As fotos dos usuários foram aplicadas nas telas de Visão Geral das ferramentas Processos, Carreira, Indicadores e Metas e Bônus.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Tabelas com scroll não eram mostradas ao fazer a impressão de PDF da Visão Geral.",
        },
        {
          description: "Ajustes na gestão de recursos por Níveis de Acesso.",
        },
      ],
    },
  ],
};

const v0231 = {
  version: "0.23.1 (20 de Julho, 2020)",
  changes: [
    {
      title: "Sistema de Atualizações Dinâmicas",
      list: [
        {
          description: "Atualização dinâmica para o Quadro das Ferramentas.",
        },
        {
          description:
            "Atualização dinâmica para Comentários e Anexos do Insight.",
        },
        {
          description:
            "Atualização dinâmica para a Administração das Ferramentas.",
        },
        {
          description:
            "Atualização dinâmica para a Gestão de Níveis de Acesso.",
        },
      ],
    },
    {
      title: "Sistema de Notificações",
      list: [
        {
          description:
            "Nova notificação ao ser adicionado como Responsável de um Insight.",
        },
        {
          description:
            "Central de Notificações foi adicionada ao cabeçalho da plataforma.",
        },
      ],
    },
    {
      title: "Sistema de Fotos",
      list: [
        {
          description:
            "A foto do usuário foi adicionada ao Workflow da ferramenta Metas e Bônus.",
        },
      ],
    },
    {
      title: "Estrutura de Áreas e Cargos",
      list: [
        {
          description: "Agora é possível excluir Áreas e Cargos.",
        },
      ],
    },
    {
      title: "Ferramenta Metas e Bônus",
      list: [
        {
          description:
            "Agora é possível selecionar Cargos diretamente da Estrutura de Cargos da Empresa na Administração da ferramenta.",
        },
        {
          description:
            "Melhorias de automatização e interatividade na Administração da ferramenta.",
        },
      ],
    },
    {
      title: "Ferramenta Portfólio de Projetos",
      list: [
        {
          description:
            "Melhorias de responsividade na Visão Geral Reporte Executivo.",
        },
      ],
    },
    {
      title: "Ferramenta OKR",
      list: [
        {
          description:
            "Agora é possível filtra os cálculos de Resultados Chave pelos valores de expectativa que foram configurados.",
        },
      ],
    },
    {
      title: "Download e Impressão de PDF",
      list: [
        {
          description:
            "Impressão e download de PDF para a Visão Geral das ferramentas OKR, Projetos, Indicadores, Metas e Bônus, SWOT, Ona Page e Processos.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "A Plataforma não estava funcionando ao entrar por outros navegadores.",
        },
      ],
    },
  ],
};

const v0230 = {
  version: "0.23.0 (7 de Julho, 2020)",
  changes: [
    {
      title: "Estrutura da Empresa",
      list: [
        {
          description:
            "Agora é possível cadastrar Áreas e Subáreas em 'Ver detalhes' da Empresa selecionada.",
        },
        {
          description:
            "Agora é possível cadastrar Cargos em 'Ver detalhes' da Empresa selecionada.",
        },
        {
          description:
            "Agora é possível vincular Áreas e Cargos ao perfil profissional dos membros e participantes da plataforma.",
        },
        {
          description:
            "Integração da Estrutura da Empresa na ferramenta Carreira.",
        },
      ],
    },
    {
      title: "Portfólio de Projetos",
      list: [
        {
          description:
            "Novos campos foram adicionados à tela Relatório e Visão Geral.",
        },
        {
          description:
            "Central de Notificações foi adicionada ao cabeçalho da plataforma.",
        },
        {
          description:
            "Melhorias gerais de interatividade nas telas de Visão Geral.",
        },
        {
          description:
            "Novo campo Visão do Escopo na visualização de Projetos replanejados e outras melhorias de interatividade.",
        },
      ],
    },
    {
      title: "Plano de Ações",
      list: [
        {
          description:
            "Agora é possível cadastrar Plano de Ações no Insight das ferramenta SWOT, OKR, Processos e Carreira.",
        },
      ],
    },
    {
      title: "Melhorias da Plataforma",
      list: [
        {
          description:
            "A foto dos usuários foi adicionada no Quadro e outras telas da Plataforma.",
        },
      ],
    },
    {
      title: "Indicadores",
      list: [
        {
          description:
            "O Módulo Medição foi adicionado à ferramenta para atualizar indicadores.",
        },
      ],
    },
    {
      title: "Correções Gerais",
      list: [
        {
          description:
            "Edição de comentários não era atualizada dinamicamente.",
        },
        {
          description: "Ajustes na gestão de recursos por Níveis de Acesso.",
        },
      ],
    },
  ],
};

const changelog = [
  { ...v118 },
  { ...v117 },
  { ...v116 },
  { ...v115 },
  { ...v114 },
  { ...v113 },
  { ...v112 },
  { ...v111 },
  { ...v110 },
  { ...v1028 },
  { ...v1027 },
  { ...v1026 },
  { ...v1025 },
  { ...v1024 },
  { ...v1023 },
  { ...v1022 },
  { ...v1021 },
  { ...v1020 },
  { ...v1019 },
  { ...v1018 },
  { ...v1017 },
  { ...v1016 },
  { ...v1015 },
  { ...v1014 },
  { ...v1013 },
  { ...v1012 },
  { ...v1011 },
  { ...v1010 },
  { ...v109 },
  { ...v108 },
  { ...v107 },
  { ...v106 },
  { ...v105 },
  { ...v104 },
  { ...v103 },
  { ...v102 },
  { ...v101 },
  { ...v100 },
  { ...v04228 },
  { ...v04227 },
  { ...v04226 },
  { ...v04225 },
  { ...v04224 },
  { ...v04223 },
  { ...v04222 },
  { ...v04221 },
  { ...v04220 },
  { ...v04219 },
  { ...v04218 },
  { ...v04217 },
  { ...v04216 },
  { ...v04215 },
  { ...v04214 },
  { ...v04213 },
  { ...v04212 },
  { ...v04211 },
  { ...v04210 },
  { ...v0429 },
  { ...v0428 },
  { ...v0427 },
  { ...v0426 },
  { ...v0425 },
  { ...v0424 },
  { ...v0423 },
  { ...v0422 },
  { ...v0421 },
  { ...v0420 },
  { ...v0410 },
  { ...v0402 },
  { ...v0401 },
  { ...v0400 },
  { ...v0393 },
  { ...v0392 },
  { ...v0391 },
  { ...v0390 },
  { ...v0383 },
  { ...v0382 },
  { ...v0381 },
  { ...v0380 },
  { ...v0372 },
  { ...v0371 },
  { ...v0370 },
  { ...v03604 },
  { ...v03603 },
  { ...v03602 },
  { ...v03601 },
  { ...v0360 },
  { ...v03510 },
  { ...v03509 },
  { ...v03508 },
  { ...v03507 },
  { ...v03506 },
  { ...v03505 },
  { ...v03504 },
  { ...v03503 },
  { ...v03502 },
  { ...v03501 },
  { ...v03500 },
  { ...v03433 },
  { ...v03432 },
  { ...v03431 },
  { ...v03430 },
  { ...v03429 },
  { ...v03428 },
  { ...v03427 },
  { ...v03426 },
  { ...v03425 },
  { ...v03424 },
  { ...v03423 },
  { ...v03422 },
  { ...v03421 },
  { ...v03420 },
  { ...v03419 },
  { ...v03418 },
  { ...v03417 },
  { ...v03416 },
  { ...v03415 },
  { ...v03412 },
  { ...v03414 },
  { ...v03413 },
  { ...v03411 },
  { ...v03410 },
  { ...v03409 },
  { ...v03319 },
  { ...v03408 },
  { ...v03407 },
  { ...v03406 },
  { ...v03405 },
  { ...v03404 },
  { ...v03403 },
  { ...v03402 },
  { ...v03401 },
  { ...v03400 },
  { ...v03327 },
  { ...v03326 },
  { ...v03325 },
  { ...v03324 },
  { ...v03323 },
  { ...v03322 },
  { ...v03321 },
  { ...v03320 },
  { ...v03318 },
  { ...v03317 },
  { ...v03316 },
  { ...v03305 },
  { ...v03315 },
  { ...v03314 },
  { ...v03313 },
  { ...v03312 },
  { ...v03311 },
  { ...v03310 },
  { ...v03309 },
  { ...v03308 },
  { ...v03307 },
  { ...v03306 },
  { ...v03304 },
  { ...v03303 },
  { ...v03302 },
  { ...v03301 },
  { ...v03300 },
  { ...v03226 },
  { ...v03225 },
  { ...v03224 },
  { ...v03223 },
  { ...v03222 },
  { ...v0270 },
  { ...v03221 },
  { ...v03220 },
  { ...v03219 },
  { ...v03218 },
  { ...v03217 },
  { ...v03216 },
  { ...v03215 },
  { ...v03214 },
  { ...v03213 },
  { ...v03212 },
  { ...v03211 },
  { ...v03210 },
  { ...v0327 },
  { ...v0326 },
  { ...v0320 },
  { ...v0311 },
  { ...v0310 },
  { ...v0301 },
  { ...v0300 },
  { ...v0291 },
  { ...v0290 },
  { ...v0281 },
  { ...v0280 },
  { ...v0271 },
  { ...v0261 },
  { ...v0260 },
  { ...v0251 },
  { ...v0250 },
  { ...v0241 },
  { ...v0240 },
  { ...v0231 },
  { ...v0230 },
];

export { changelog };
